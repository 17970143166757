import React, { useCallback, useEffect, useState } from 'react';
import { withAccess } from '../../auth-context/can-access';
import { EUserPermissions } from '../../app/enums';
import { Box, Button, Chip, CircularProgress, Divider, IconButton, List, ListItem, Snackbar } from '@mui/material';

import './branch.scss';
import { api } from '../../helpers/api.helper';
import { Link, useParams } from 'react-router-dom';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Alert } from '@mui/lab';
import { ETestLastSentStatus, ETestShowStatus, IBranchData, ITestData } from '../../interfaces';

interface IToast {
  message: string;
  type: 'success' | 'info' | 'warning' | 'error';
}

const getGitlabLink = (branch: IBranchData) => `${branch.server_url}/${branch.project_path}/-/tree/${branch.branch}`;

const getBranchLink = (branch_env: string) => {
  const non_feature_branches = ['rc', 'beta'];
  const feature_middle = non_feature_branches.includes(branch_env) ? '' : '.feature';
  return `https://${branch_env}${feature_middle}.trainingspace.online`;
};

const getTestStatus = (test: ITestData): ETestShowStatus =>
  (test.last_sent_status === ETestLastSentStatus.NONE ? ETestShowStatus.QUEUED : test.status) as ETestShowStatus;

const showDashboardButton = (test: ITestData) =>
  test.run_link &&
  [ETestShowStatus.RUNNING, ETestShowStatus.PASSED, ETestShowStatus.FAILED].includes(getTestStatus(test));

const BranchComponent = () => {
  const { branch_id } = useParams<{ branch_id: string }>();
  const [branchData, setBranchData] = useState<IBranchData>();
  const [toast, setToast] = useState<IToast>();

  useEffect(() => {
    const getFeatureBranch = async () => {
      api.feature_branch
        .show(branch_id)
        .then(({ data }) => data[0])
        .then((branch) => {
          setBranchData((prevData) => {
            if (prevData?.tests) {
              prevData.tests.forEach((prevTestData, index) => {
                const currentTestData = branch.tests.find(({ id }) => prevTestData.id === id);
                if (currentTestData && getTestStatus(currentTestData) !== getTestStatus(prevTestData)) {
                  const text = `Test #${prevData.tests.length - index} status updated to ${getTestStatus(
                    currentTestData
                  )}`;
                  new Notification('Cypress test', { body: text });
                }
              });
            }

            return { ...branch, tests: branch.tests.reverse() };
          });
        })
        .catch((e) => setToast({ message: `Error: ${e}`, type: 'error' }));
    };

    getFeatureBranch();

    const interval = setInterval(() => getFeatureBranch(), 5000);
    return () => clearInterval(interval);
  }, [branch_id]);

  const handleStartDiff = useCallback(
    () =>
      api.cypress_test
        .start_diff(branch_id)
        .then(() => setToast({ message: 'Diff test started successfully', type: 'success' }))
        .catch((e) => setToast({ message: `Error: ${e}`, type: 'error' })),
    [branch_id]
  );

  const handleStartAll = useCallback(
    () =>
      api.cypress_test
        .start_full(branch_id)
        .then(() => setToast({ message: 'All test started successfully', type: 'success' }))
        .catch((e) => setToast({ message: `Error: ${e}`, type: 'error' })),
    [branch_id]
  );

  const handleStop = useCallback(
    (test_id: string) =>
      api.cypress_test
        .stop(test_id)
        .then(() => setToast({ message: 'Test stopped successfully', type: 'success' }))
        .catch((e) => setToast({ message: `Error: ${e}`, type: 'error' })),
    []
  );

  const closeToast = useCallback(() => setToast(null), []);

  useEffect(() => {
    if (Notification.permission !== 'granted') {
      Notification.requestPermission();
    }
  }, []);

  if (!branchData) {
    return (
      <div className="branch__loader">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <Box className="branch__header">
        <span className="header__link">
          <Link to="/branches">
            <ArrowBack />
          </Link>
        </span>
        <h2 className="header__title">Branch</h2>
      </Box>

      <Box className="box branch__content">
        <List>
          <ListItem className="content__title content__no-margin">
            <span>Info</span>
            <IconButton href={getBranchLink(branchData.env)} target="_blank" rel="noopener noreferrer">
              <ArrowForward />
            </IconButton>
          </ListItem>
          <Divider component="li" />
          <ListItem className="content__title content__no-margin">
            <BranchInfo branchData={branchData} />
          </ListItem>

          <ListItem className="content__title">
            <span>Tests</span>
            <div className="content__buttons-group">
              <Button variant="contained" onClick={handleStartDiff}>
                Run diff
              </Button>
              <Button variant="contained" color="success" onClick={handleStartAll}>
                Run all
              </Button>
            </div>
          </ListItem>

          {branchData.tests && (
            <>
              <Divider component="li" />
              <ListItem
                className="content__title content__no-margin"
                children={<Tests tests={branchData.tests} onStop={handleStop} />}
              />
            </>
          )}
        </List>
      </Box>
      <Snackbar
        className="toast"
        onClose={closeToast}
        open={!!toast}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        {toast && (
          <Alert onClose={closeToast} severity={toast.type}>
            <p className="branch__toast">{toast.message}</p>
          </Alert>
        )}
      </Snackbar>
    </>
  );
};

const BranchInfo = React.memo(({ branchData }: { branchData: IBranchData }) => {
  return (
    <List className="branch-info">
      <ListItem className="branch-info__item">
        <span>Env</span>
        <span>{branchData.env}</span>
      </ListItem>
      <Divider component="li" />
      <ListItem className="branch-info__item">
        <span>Branch</span>
        <span>{branchData.branch}</span>
      </ListItem>
      <Divider component="li" />
      <ListItem className="branch-info__item">
        <span>Owner</span>
        <span>{branchData.owner_email}</span>
      </ListItem>
      <Divider component="li" />
      <ListItem className="branch-info__item">
        <span>GitLab</span>
        <Chip
          className="branch-info__chip"
          label={branchData.project_path}
          component="a"
          href={getGitlabLink(branchData)}
          target="_blank"
          rel="noopener noreferrer"
        />
      </ListItem>
    </List>
  );
});

const Tests = React.memo(({ tests, onStop }: { tests: ITestData[]; onStop: (test_id: string) => void }) => {
  return (
    <List className="test-info">
      {tests.map((test, index) => {
        const test_status = getTestStatus(test);
        return (
          <React.Fragment key={test.id}>
            <ListItem className="test-info__item">
              <div className="test-info__title">
                <div className="test-info__position-wrapper">
                  <span>#{tests.length - index}</span>
                </div>
                <div className="test-info__status-wrapper">
                  <Chip className={`test-info__status test-info__status--${test_status}`} label={test_status} />
                </div>
                <Chip className="test-info__status" label={test.type} />
              </div>

              <div className="content__buttons-group">
                {test_status === ETestShowStatus.RUNNING && (
                  <Button variant="contained" color="error" onClick={() => onStop}>
                    Stop
                  </Button>
                )}
                {showDashboardButton(test) && (
                  <Button
                    className="content__button"
                    component={'a'}
                    href={test.run_link}
                    variant="outlined"
                    endIcon={<ArrowForward />}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Dashboard
                  </Button>
                )}
              </div>
            </ListItem>
            {index !== tests.length - 1 && <Divider component="li"></Divider>}
          </React.Fragment>
        );
      })}
    </List>
  );
});

export const BranchPage = withAccess(EUserPermissions.FEATURE_BRANCHES)(BranchComponent);
